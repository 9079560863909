import { Language } from '../utils/enums';

import en from './en.json';
import ru from './ru.json';

export type Locales = Record<Language, Record<string, string>>;

export const locales: Locales = {
  en,
  ru,
};
