import { List } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';

import ErrorInfo from '../../components/errorInfo';

import { AggregatedPassenger } from './utils';

interface PassengersListProps {
  header: string;
  aggregatedPassengers: AggregatedPassenger[];
  onClick: (aggregatedPassenger: AggregatedPassenger) => void;
}

export const PassengersList = ({
  header,
  aggregatedPassengers,
  onClick,
}: PassengersListProps) => {
  const intl = useIntl();

  return (
    <List header={header}>
      {aggregatedPassengers.map((aggregatedPassenger, index) => {
        const { passenger, ticket, order, currencySymbol } =
          aggregatedPassenger;

        const description = (
          <div className="passenger-list">
            {order?.paymentMethod && (
              <div className="passenger-list__payment-info">
                <span className="passenger-list__price">
                  {ticket.ticketPrice} {currencySymbol}
                </span>
                {ticket.seatNumber && (
                  <span className="passenger-list__seat">
                    <FormattedMessage id="passengers.seatNumber" />{' '}
                    {ticket.seatNumber}
                  </span>
                )}
                <FormattedMessage
                  id={`passengers.payment.${order.paymentMethod}`}
                />
              </div>
            )}

            {ticket?.comment && (
              <div className="passenger-list__comment">
                <span className="passenger-list__comment-label">
                  <FormattedMessage id="passengers.comment" />:
                </span>{' '}
                {ticket.comment}
              </div>
            )}
          </div>
        );

        return (
          <List.Item
            key={passenger?.id || index}
            extra={
              ticket?.status && (
                <div className="passenger-list__status">
                  <FormattedMessage id={`passengers.status.${ticket.status}`} />
                </div>
              )
            }
            description={description}
            onClick={() => onClick(aggregatedPassenger)}
          >
            {passenger ? (
              <div className="passenger-list__name">
                {passenger.firstName} {passenger.lastName}
              </div>
            ) : (
              <ErrorInfo
                description={intl.formatMessage({ id: 'error.noPassengers' })}
                image={null}
              />
            )}
          </List.Item>
        );
      })}
    </List>
  );
};
