import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ActionSheet, DotLoading, Skeleton, Toast } from 'antd-mobile';
import { Typography, Collapse, ConfigProvider } from 'antd';
import { UpOutline, DownOutline } from 'antd-mobile-icons';
import { useIntl } from 'react-intl';

import {
  EOrderStatus_Input,
  OrderWithTickets,
} from '../../__generated__/types';
import { showError } from '../../utils';
import { NavBar } from '../../components/nav-bar';
import ErrorInfo from '../../components/errorInfo';
import { Routes } from '../../utils/enums';

import { useGetRawFlight } from './gql/__generated__/GetRawFlight';
import { useGetPassengersByStations } from './gql/__generated__/GetPassengersByStations';
import { useChangeStatus } from './gql/__generated__/ChangeStatus';
import {
  AggregatedPassenger,
  aggregatePassengers,
  getPassengerActions,
} from './utils';
import { PassengersList } from './list';
import './index.scss';

dayjs.extend(localizedFormat);

const PassengersPage = () => {
  const navigate = useNavigate();
  const { flightId } = useParams();
  const intl = useIntl();

  const [visible, setVisible] = useState(false);
  const [selectedPassenger, setSelectedPassenger] =
    useState<AggregatedPassenger>();

  const {
    data: flightData,
    loading: flightLoading,
    error: flightError,
  } = useGetRawFlight({
    skip: !flightId,
    variables: {
      req: {
        id: flightId!,
      },
    },
    fetchPolicy: 'network-only',
    onError: showError,
  });

  const flight = flightData?.flights_getRaw?.flight || null;

  const {
    data: passengersData,
    loading: passengersLoading,
    error: passengersError,
  } = useGetPassengersByStations({
    skip: !flightId,
    variables: {
      req: {
        flightId: flightId!,
        statuses: [
          EOrderStatus_Input.Active,
          EOrderStatus_Input.Confirmed,
          EOrderStatus_Input.NotCome,
        ],
      },
    },
    fetchPolicy: 'network-only',
    onError: showError,
  });

  const [changeStatus] = useChangeStatus({ onError: showError });

  const stations = passengersData?.tickets_getPassengersByStations?.items || [];

  const colorIcon = 'var(--adm-color-light)';

  const handleBack = () => {
    navigate(Routes.GO_BACK);
  };

  const handleChangeStatus = async (
    ticketId: string,
    status: EOrderStatus_Input,
  ) => {
    try {
      const res = await changeStatus({
        variables: {
          req: {
            ticketId,
            status,
          },
        },
      });
      if (res.errors) {
        throw Error();
      }
      Toast.show({
        icon: 'success',
        content: intl.formatMessage({ id: 'passengers.status.updated' }),
      });
      setVisible(false);
    } catch (e) {
      Toast.show({
        icon: 'fail',
        content: intl.formatMessage({ id: 'passengers.status.updated.error' }),
      });
    }
  };

  const handlePassengerClick = (aggregatedPassenger: AggregatedPassenger) => {
    setSelectedPassenger(aggregatedPassenger);
    setVisible(true);
  };

  const getPassengersList = () => {
    return stations.map((stationData, index) => {
      const { station, city, getOff = [], seat = [] } = stationData || {};

      const aggregatedSeatPassengers = aggregatePassengers(
        seat as OrderWithTickets[],
      );
      const aggregatedGetOffPassengers = aggregatePassengers(
        getOff as OrderWithTickets[],
      );

      return {
        key: index,
        styles: { header: { alignItems: 'center' } },
        label: (
          <h3 className="station">
            {city} ({station})
          </h3>
        ),
        children: (
          <>
            {aggregatedSeatPassengers.length > 0 && (
              <PassengersList
                header={`${intl.formatMessage({ id: 'passengers.flight.onboarding' })} (${aggregatedSeatPassengers.length})`}
                aggregatedPassengers={aggregatedSeatPassengers}
                onClick={handlePassengerClick}
              />
            )}

            {aggregatedGetOffPassengers.length > 0 && (
              <PassengersList
                header={`${intl.formatMessage({ id: 'passengers.flight.landing' })} (${aggregatedGetOffPassengers.length})`}
                aggregatedPassengers={aggregatedGetOffPassengers}
                onClick={handlePassengerClick}
              />
            )}
          </>
        ),
      };
    });
  };

  const renderExpandIcon = ({ isActive }: { isActive?: boolean }) => (
    <div>
      {isActive ? (
        <UpOutline color={colorIcon} fontSize={19} />
      ) : (
        <DownOutline color={colorIcon} fontSize={19} />
      )}
    </div>
  );

  const defaultCollapseActiveKeys = stations.map((_, i) => i);

  return (
    <div className="passengers-page">
      <NavBar onBack={handleBack}>
        {flightLoading ? (
          <DotLoading />
        ) : flightError || !flight ? (
          <ErrorInfo
            description={intl.formatMessage({ id: 'error.getFlight' })}
            image={null}
          />
        ) : (
          <>
            <div>{flight.name}</div>
            <div>
              <Typography.Text type="secondary">
                {dayjs(flight.departureTime).format('LLLL')}
              </Typography.Text>
            </div>
          </>
        )}
      </NavBar>

      <div className="passengers-page__body">
        {passengersLoading ? (
          <>
            <Skeleton.Title />
            <Skeleton.Paragraph />
            <Skeleton.Paragraph />
          </>
        ) : passengersError ? (
          <ErrorInfo
            description={intl.formatMessage({ id: 'error.getPassengers' })}
          />
        ) : !stations?.length ? (
          <ErrorInfo
            description={intl.formatMessage({ id: 'passengers.noPassengers' })}
          />
        ) : (
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  contentPadding: '0',
                  headerBg: 'transparent',
                  headerPadding: '4px 12px',
                },
              },
            }}
          >
            <Collapse
              expandIconPosition="end"
              bordered={false}
              className="collapse"
              defaultActiveKey={defaultCollapseActiveKeys}
              expandIcon={renderExpandIcon}
              items={getPassengersList()}
            />
          </ConfigProvider>
        )}
      </div>

      <ActionSheet
        visible={visible}
        actions={getPassengerActions(selectedPassenger, handleChangeStatus)}
        onClose={() => setVisible(false)}
      />
    </div>
  );
};

export default PassengersPage;
