import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useLogin } from '../../hooks';

const Auth = () => {
  const { login } = useLogin();

  return (
    <div>
      <h1>
        <FormattedMessage id="auth.authentication" />
      </h1>
      <Button onClick={login}>
        <FormattedMessage id="auth.login" />
      </Button>
    </div>
  );
};

export default Auth;
