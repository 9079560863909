import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { RawFlightFragment } from '../gql/__generated__/RawFlightFragment.fragment';

dayjs.extend(localizedFormat);

const LABEL_YESTERDAY = 'Вчера';
const LABEL_TODAY = 'Сегодня';
const LABEL_TOMORROW = 'Завтра';

const today = dayjs().startOf('day');
const twoDaysAgo = today.clone().subtract(2, 'days');
const yesterday = today.clone().subtract(1, 'days');
const tomorrow = today.clone().add(1, 'days');

export function getSortWeight(dateLabel: string) {
  if (dateLabel === LABEL_YESTERDAY) return 1;
  if (dateLabel === LABEL_TODAY) return 2;
  if (dateLabel === LABEL_TOMORROW) return 3;
  return 4; // Все остальные даты получают вес 4
}

export function groupFlightsByDate(
  flights: (RawFlightFragment | null)[],
): Record<string, RawFlightFragment[]> {
  return flights.reduce<Record<string, RawFlightFragment[]>>((acc, flight) => {
    if (!flight || !flight.departureTime) return acc;

    const departure = dayjs(flight.departureTime);

    if (departure.isBefore(twoDaysAgo, 'day')) {
      return acc;
    }

    const key = departure.isSame(yesterday, 'day')
      ? LABEL_YESTERDAY
      : departure.isSame(today, 'day')
        ? LABEL_TODAY
        : departure.isSame(tomorrow, 'day')
          ? LABEL_TOMORROW
          : departure.format('DD MMMM YYYY');

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(flight);
    return acc;
  }, {});
}

export function getSortedFlightsByDateLabels(
  flightsByDate: Record<string, RawFlightFragment[]>,
  getSortWeight: (label: string) => number,
): [string, RawFlightFragment[]][] {
  return Object.entries(flightsByDate).sort((a, b) => {
    const weightA = getSortWeight(a[0]);
    const weightB = getSortWeight(b[0]);

    if (weightA !== weightB) {
      return weightA - weightB;
    }

    return dayjs(a[0], 'DD MMMM YYYY').diff(dayjs(b[0], 'DD MMMM YYYY'));
  });
}
