import {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
} from 'react';

import { Theme } from '../../utils/enums';
import { THEME_KEY } from '../../constants';
import { useThemeDetector } from '../../hooks';

type ThemeContextType = {
  theme: Theme;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType,
);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  return context;
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const deviceTheme = useThemeDetector();

  const getInitialTheme = (): Theme => {
    const savedTheme = localStorage.getItem(THEME_KEY) as Theme;
    return Object.values(Theme).includes(savedTheme) ? savedTheme : deviceTheme;
  };

  const [theme, setTheme] = useState<Theme>(getInitialTheme);

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
      document.documentElement.setAttribute(
        'data-prefers-color-scheme',
        newTheme,
      );

      localStorage.setItem(THEME_KEY, newTheme);

      return newTheme;
    });
  }, []);

  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-prefers-color-scheme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
