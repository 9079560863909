import { notification } from 'antd';

import { Routes } from './enums';

export const showError = (err: Error) => {
  notification.error({
    message: 'Operation Failed',
    description: err.message,
    duration: 3,
  });
};

export const getRoute = (
  route: Routes,
  params: Record<string, string | number>,
) => {
  let path: string = route;
  Object.entries(params).forEach(([key, value]) => {
    path = path.replace(`:${key}`, String(value));
  });
  return path;
};
