import { Empty as EmptyMobile } from 'antd-mobile';
import { Empty } from 'antd';

interface ErrorInfoProps {
  description: string;
  image?: React.ReactNode | null;
}

const ErrorInfo = ({
  description,
  image = Empty.PRESENTED_IMAGE_SIMPLE,
}: ErrorInfoProps) => <EmptyMobile description={description} image={image} />;

export default ErrorInfo;
