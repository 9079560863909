import { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TabBar } from 'antd-mobile';
import { TruckOutline, UserOutline } from 'antd-mobile-icons';
import { FormattedMessage } from 'react-intl';

import { Routes } from '../../utils/enums';

import './index.scss';

const Bottom: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const tabs = [
    {
      key: 'flights',
      title: <FormattedMessage id="menu.flights" />,
      icon: <TruckOutline />,
      onClick: () => {
        navigate(Routes.HOME);
      },
    },
    {
      key: 'account',
      title: <FormattedMessage id="menu.account" />,
      icon: <UserOutline />,
      onClick: () => {
        navigate(Routes.ACCOUNT);
      },
    },
  ];

  return (
    <TabBar activeKey={pathname} className="tab-bar">
      {tabs.map((item) => (
        <TabBar.Item
          key={item.key}
          icon={item.icon}
          title={item.title}
          onClick={item.onClick}
        />
      ))}
    </TabBar>
  );
};

const MobileLayout = () => {
  return (
    <div className="app">
      <div className="app__body">
        <Outlet />
      </div>
      <div className="app__bottom">
        <Bottom />
      </div>
    </div>
  );
};

const Layout = () => {
  return (
    <div className="layout">
      <div className="layout__inner">
        <MobileLayout />
      </div>
    </div>
  );
};
export default Layout;
