import { createContext, ReactNode, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import 'dayjs/locale/en-gb.js';

import { LOCAL_STORAGE_KEY_LOCALE } from '../../constants';
import { Language } from '../../utils/enums';

type LocaleContextType = {
  locale: Language;
  changeLanguage: (language: Language) => void;
  defaultLocale: Language;
};

export const LocaleContext = createContext<LocaleContextType>(
  {} as LocaleContextType,
);

export const LocaleProvider = ({ children }: { children: ReactNode }) => {
  const defaultLocale = Language.Ru;
  const [locale, setLocale] = useState<Language>(defaultLocale);

  useEffect(() => {
    const stored = localStorage.getItem(LOCAL_STORAGE_KEY_LOCALE) as Language;
    const isValid = [Language.En, Language.Ru].includes(stored);
    const locale = isValid ? stored : defaultLocale;
    dayjs.locale(locale);

    document.documentElement.lang = locale;
    setLocale(locale);
  }, []);

  const changeLanguage = (language: Language) => {
    if (language !== locale) {
      localStorage.setItem(LOCAL_STORAGE_KEY_LOCALE, language);
      document.documentElement.lang = language;
      setLocale(language);
      dayjs.locale(language);
    }
  };

  return (
    <LocaleContext.Provider value={{ locale, changeLanguage, defaultLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};
