import { Outlet, RouteObject } from 'react-router-dom';
import { Result } from 'antd';
import { FormattedMessage } from 'react-intl';

import ProtectedRoutes from './components/protected';
import Auth from './components/auth';
import AuthLayout from './components/layouts/AuthLayout';
import AppLayout from './components/layouts/AppLayout';
import Logout from './components/logout';
import Callback from './components/callback';
import FlightsPage from './pages/flights/FlightsPage';
import PassengersPage from './pages/passengers';
import AccountPage from './pages/account';
import { Routes } from './utils/enums';

const redirectPath =
  process.env.REACT_APP_ORY_REDIRECT_PATH || Routes.REDIRECT_PATH;

export const routes: RouteObject[] = [
  {
    path: '',
    element: <Outlet />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: Routes.LOGIN,
            element: <Auth />,
          },
          {
            path: redirectPath,
            element: <Callback />,
          },
          {
            path: Routes.LOGOUT,
            element: <Logout />,
          },
        ],
      },
      {
        path: '',
        element: <ProtectedRoutes />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                path: Routes.HOME,
                element: <FlightsPage />,
              },
              {
                path: Routes.PASSENGERS,
                element: <PassengersPage />,
              },
              {
                path: Routes.ACCOUNT,
                element: <AccountPage />,
              },
              {
                path: '*',
                element: (
                  <Result
                    status={404}
                    title="404"
                    subTitle={<FormattedMessage id="error.errorPage" />}
                  />
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];
