import { Action } from 'antd-mobile/es/components/action-sheet';
import { Dialog } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';

import {
  EOrderStatus,
  EOrderStatus_Input,
  Order,
  OrderWithTickets,
  Passenger,
  Ticket,
} from '../../__generated__/types';

export interface AggregatedPassenger {
  passenger: Passenger;
  ticket: Ticket;
  order: Order;
  currencySymbol: string;
}

export const aggregatePassengers = (
  arr: OrderWithTickets[],
): AggregatedPassenger[] =>
  arr
    .map((item) => {
      const tickets = (item?.tickets || []).map((t) => ({
        ...t,
        order: item?.order,
        currencySymbol: item.currencySymbol,
      }));

      return {
        ...item,
        tickets,
      };
    })
    .flatMap((item) => item?.tickets) as AggregatedPassenger[];

export function getPassengerActions(
  aggregatedPassenger: AggregatedPassenger | undefined,
  handleChangeStatus: (ticketId: string, status: EOrderStatus_Input) => void,
): Action[] {
  if (!aggregatedPassenger) {
    return [];
  }

  const { passenger, ticket } = aggregatedPassenger;

  return [
    {
      text: (
        <div
          onClick={() => window.open(`tel:${passenger.phoneNumber}`)}
          className="tel-link"
        >
          <FormattedMessage id="passengers.menu.call" />
        </div>
      ),
      description: `+${passenger.phoneNumber}`,
      key: 'call',
    },
    {
      text: <FormattedMessage id="passengers.menu.came" />,
      key: 'come',
      disabled: ticket.status !== EOrderStatus.Active,
      onClick: () => {
        handleChangeStatus(ticket.id, EOrderStatus_Input.Confirmed);
      },
    },
    {
      text: <FormattedMessage id="passengers.menu.notCome" />,
      key: 'not-come',
      disabled: ticket.status !== EOrderStatus.Active,
      danger: true,
      onClick: () => {
        Dialog.confirm({
          content: (
            <FormattedMessage id="passengers.menu.notCome.description" />
          ),
          confirmText: (
            <FormattedMessage id="passengers.menu.notCome.confirm" />
          ),
          cancelText: <FormattedMessage id="passengers.menu.notCome.cancel" />,
          onConfirm: async () => {
            handleChangeStatus(ticket.id, EOrderStatus_Input.NotCome);
          },
        });
      },
    },
  ];
}
