import { AutoCenter, Button, List, Switch } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { Select, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToken } from '../flights/FlightsPage/useToken';
import { Avatar } from '../../components/avatar';
import { Language, Routes, Theme } from '../../utils/enums';
import ErrorInfo from '../../components/errorInfo';
import { useTheme } from '../../components/themeProvider';
import { useLocale } from '../../hooks';

import './index.scss';

const AccountPage = () => {
  const navigate = useNavigate();
  const token = useToken();
  const { theme, toggleTheme } = useTheme();
  const { locale, changeLanguage } = useLocale();
  const intl = useIntl();

  const handleLogout = () => {
    navigate(Routes.LOGOUT);
  };

  const settingsConfig = [
    {
      key: 'theme',
      label: <FormattedMessage id="account.darkTheme" />,
      extra: <Switch checked={theme === Theme.DARK} onChange={toggleTheme} />,
    },
    {
      key: 'language',
      label: <FormattedMessage id="account.language" />,
      extra: (
        <Select
          options={[
            {
              label: <FormattedMessage id="account.language.ru" />,
              value: Language.Ru,
            },
            {
              label: <FormattedMessage id="account.language.en" />,
              value: Language.En,
            },
          ]}
          defaultValue={locale}
          onChange={(val) => changeLanguage(val)}
        />
      ),
    },
  ];

  return (
    <div className="account-page">
      <div className="account-page__body">
        {token ? (
          <>
            <AutoCenter>
              <Avatar
                firstName={String(token.email)}
                lastName={' '}
                size={80}
              />
            </AutoCenter>
            <AutoCenter>
              <Typography.Title level={3} className="account-page__email-title">
                {token.email}
              </Typography.Title>
            </AutoCenter>
            <List header={<FormattedMessage id="account.settings" />}>
              {settingsConfig.map(({ key, label, extra }) => (
                <List.Item key={key} extra={extra}>
                  <Typography.Text>{label}</Typography.Text>
                </List.Item>
              ))}
            </List>
          </>
        ) : (
          <ErrorInfo
            description={intl.formatMessage({ id: 'error.getEmail' })}
          />
        )}
      </div>

      <div className="account-page__exit-button">
        <Button block color="danger" onClick={handleLogout}>
          <FormattedMessage id="account.logout" />
        </Button>
      </div>
    </div>
  );
};

export default AccountPage;
