import { Navigate, Outlet } from 'react-router-dom';

import { usesAuthenticated } from '../../hooks';
import { Routes } from '../../utils/enums';

function ProtectedRoutes() {
  const { isAuthenticated } = usesAuthenticated();

  return <>{isAuthenticated ? <Outlet /> : <Navigate to={Routes.LOGIN} />}</>;
}

export default ProtectedRoutes;
