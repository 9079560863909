import { List, Skeleton } from 'antd-mobile';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { FormattedMessage, useIntl } from 'react-intl';

import { NavBar } from '../../../components/nav-bar';
import ErrorInfo from '../../../components/errorInfo';
import { getRoute, showError } from '../../../utils';
import { Routes } from '../../../utils/enums';
import { useGetAllRawFlights } from '../gql/__generated__/GetAllRawFlights';
import type { RawFlightFragment } from '../gql/__generated__/RawFlightFragment.fragment';
import { EFlightStatus_Input } from '../../../__generated__/types';

import { useToken } from './useToken';
import {
  getSortedFlightsByDateLabels,
  getSortWeight,
  groupFlightsByDate,
} from './utils';
import './index.scss';

dayjs.extend(localizedFormat);

const FlightsPage = () => {
  const token = useToken();
  const navigate = useNavigate();
  const intl = useIntl();

  const { data, loading: flightsLoading } = useGetAllRawFlights({
    skip: !token?.email,
    variables: {
      req: {
        driverEmail: token?.email,
        statuses: [EFlightStatus_Input.Sale],
        flightIds: [],
        departureStartDate: dayjs()
          .startOf('day')
          .subtract(1, 'days')
          .format('YYYY-MM-DDTHH:mm:ss'),
        departureEndDate: dayjs()
          .endOf('day')
          .add(15, 'days')
          .format('YYYY-MM-DDTHH:mm:ss'),
      },
    },
    fetchPolicy: 'network-only',
    onError: showError,
  });

  const flights = data?.flights_getAllRaw?.flights || [];
  const flightsByDate = groupFlightsByDate(flights);
  const sortedFlights = getSortedFlightsByDateLabels(
    flightsByDate,
    getSortWeight,
  );
  const empty = !Object.keys(flightsByDate).length;

  const handleSelectEvent = useCallback((flight: RawFlightFragment) => {
    navigate(getRoute(Routes.PASSENGERS, { flightId: flight.id }));
  }, []);

  return (
    <div className="flight-page">
      <NavBar>
        <FormattedMessage id="menu.flights" />
      </NavBar>

      <div className="flight-page__body">
        {flightsLoading ? (
          <>
            <Skeleton.Title />
            <Skeleton.Paragraph />
            <Skeleton.Paragraph />
          </>
        ) : empty ? (
          <ErrorInfo
            description={intl.formatMessage({
              id: 'flight.noFlightsAvailable',
            })}
          />
        ) : (
          sortedFlights.map(([date, flights]) => (
            <div key={date}>
              <List header={date}>
                {flights.map((flight) => (
                  <List.Item
                    key={v4()}
                    onClick={() => handleSelectEvent(flight)}
                    description={
                      <>
                        <FormattedMessage id="flight.departure" />:{' '}
                        {dayjs(flight.departureTime).format('LT')}
                      </>
                    }
                  >
                    {flight.name}
                  </List.Item>
                ))}
              </List>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FlightsPage;
