import { createRoot } from 'react-dom/client';

import App from './App';
import './index.scss';
import { ThemeProvider } from './components/themeProvider';
import { LocaleProvider } from './components/localeProvider';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ThemeProvider>
    <LocaleProvider>
      <App />
    </LocaleProvider>
  </ThemeProvider>,
);
