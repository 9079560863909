import {
  NavBar as AntdNavBar,
  NavBarProps as AntdNavBarProps,
} from 'antd-mobile';

import './index.scss';

interface NavBarProps extends AntdNavBarProps {
  children?: React.ReactNode;
}

export const NavBar = ({ children, ...props }: NavBarProps) => {
  return (
    <AntdNavBar className="nav-bar" backIcon={!!props.onBack} {...props}>
      {children}
    </AntdNavBar>
  );
};
