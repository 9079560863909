import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { authService } from '../services/auth-service';
import { Routes, Theme } from '../utils/enums';
import { LocaleContext } from '../components/localeProvider';

export const useLogin = () => {
  const login = () => {
    authService.login();
  };

  return { login };
};

export const useToken = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get('code');

    if (!authService.checkIfLoggedIn()) {
      code &&
        authService
          .makeRefreshTokenRequest(code)
          .then(() => {
            navigate(Routes.HOME);
          })
          .catch((err) => {
            console.error(err);
          });
    }
  }, []);
};

export const useLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
  }, []);

  const logout = async () => {
    try {
      await authService.logout();
    } catch (error) {
      console.error(error);
    }
    navigate(Routes.LOGIN);
  };
};

export const usesAuthenticated = () => {
  const isAuthenticated = authService.checkIfLoggedIn();

  return { isAuthenticated };
};

export const useThemeDetector = (): Theme => {
  const getCurrentTheme = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? Theme.DARK
      : Theme.LIGHT;

  const [theme, setTheme] = useState<Theme>(getCurrentTheme);

  const mqListener = (e: MediaQueryListEvent) => {
    setTheme(e.matches ? Theme.DARK : Theme.LIGHT);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

    darkThemeMq.addEventListener('change', mqListener);

    return () => {
      darkThemeMq.removeEventListener('change', mqListener);
    };
  }, []);

  return theme;
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  return context;
};
