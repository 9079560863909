import { FormattedMessage } from 'react-intl';

import { useLogout } from '../../hooks';

const Logout = () => {
  useLogout();

  return (
    <h1>
      <FormattedMessage id="auth.logout" />
    </h1>
  );
};

export default Logout;
