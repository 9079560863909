export enum Routes {
  HOME = '/',
  LOGOUT = '/logout',
  LOGIN = '/login',
  REDIRECT_PATH = '/callback',
  PASSENGERS = '/passengers/:flightId',
  ACCOUNT = '/account',
  GO_BACK = '../',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum Language {
  Ru = 'ru',
  En = 'en',
}
